<template>
  <div
    class="modal"
    style="z-index: 1006 !important;"
    v-bind:class="{ 'is-active': showChangingRegionModal }"
  >
  <div class="modal-background"></div>
  <div class="modal-card" style="border-radius: 25px;">
    <section class="modal-card-body">
      Processing ...
    </section>
  </div>
  </div>
</template>

<script>

export default {
  data () {
    return {}
  },
  props: {
    showChangingRegionModal: {
      type: Boolean
    }
  },
  mounted () {
    //
  }
}
</script>
<style lang="scss" scoped>
  @import "~leaflet/dist/leaflet.css";
  .custom .leaflet-popup-tip,
  .custom .leaflet-popup-content-wrapper {
      background: #e93434;
      color: #ffffff;
  }
</style>
